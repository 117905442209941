/**
 * Application entry point
 */

// Load application styles
import '../assets/styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================
